<template>
  <svg xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    :viewBox="viewBox" 
    fill="none"
    role="presentation"
  >
    <slot />
  </svg>
</template>


<script lang="js">
export default {
  name: 'BaseIllustration',
  props: {
    width: {
      type: [Number, String],
    },
    height: {
      type: [Number, String],
    },
    viewBox: {
      type: String,
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./base-illustration";
</style>
